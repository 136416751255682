const state = {
    translations: {},
    customerInfo: {},
};

import apiUrl from "../../helpers/apiUrl"
import axios from 'axios'

const getters = {
    getCustomerInfo: (state) => state.customerInfo,
    getTranslations: (state) => state.translations
};

const actions = {
    fetchTranslations(context, payload){
        if (payload) {
            axios.get(apiUrl.moveInOutTranslations, { 'headers': { 'Accept-Language': payload } }).then(response => {
                context.commit('updateTranslations', response.data.data.translations);
            })
        } else {
            axios.get(apiUrl.moveInOutTranslations).then(response => {
                context.commit('updateTranslations', response.data.data.translations);
            })
        }
    },
    fetchCustomerInfo(context){
        return axios.get(apiUrl.moveInOutCustumerInfo).then(response => {
            context.commit('updateCustomerInfo', response.data.data);
        })
    },
    updateCustomerInfo(context, payload) {
        return axios.post(apiUrl.moveInOutUpdateCustomerInfo, payload);
    }
};

const mutations = {    
    updateTranslations(state, responseData){
        state.translations = responseData;
    },
    updateCustomerInfo(state, field){
        state.customerInfo = field;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}