const state = {
    formFields: {
        Phone: "",
        Email: "",
        MoveOutDate: "",        
        BillingStreetName: "",
        BillingStreetNumber: "",
        BillingPostalCode: "",
        BillingCity: "",
        BillingCo: "",
        BillingApartmentNumber: "",
    },
    responseError: "",
    responseObj: {},
    SelectedDeliverySite: {}    
};

import apiUrl from "../../helpers/apiUrl"
import responseErrors from "../../helpers/responseErrors"
import axios from 'axios'

const getters = {
    getForm: (state) => state.formFields,
    getData: (state) => state.data,
    getSelectedDeliverySite: (state) => state.SelectedDeliverySite
};

const actions = {    
    clearState(context) {
        context.commit('clearState')
    },
    moveOut(context){
        const d = context.state.formFields.MoveOutDate;
        const phone = context.state.formFields.Phone;
        const moveOutObj = {};
        moveOutObj.customerId = "",
        moveOutObj.address = {
            firstName: context.rootState.move.customerInfo.firstName || "",
            lastName: context.rootState.move.customerInfo.lastName || "",
            fullName: `${context.rootState.move.customerInfo.firstName} ${context.rootState.move.customerInfo.lastName}`,
            ssn: context.rootState.move.customerInfo.personalIdentityNumber || "",
            email: context.state.formFields.Email || "",

            mobile: phone ? phone.number : "",
            //code used before country code was added
            //mobile: phone ? (phone.substr(0, 1) === '0' && phone.length === 10 ? phone : `0${phone}`) : "",

            homePhone: ""
        },
        moveOutObj.deliverySiteId = context.state.SelectedDeliverySite.deliverySiteId || "";
        moveOutObj.MoveOutDate = context.state.formFields.MoveOutDate ? `${d.getFullYear()}-${("0"+(d.getMonth()+1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}` : "";
        moveOutObj.invoiceAddress = {
            streetName: context.state.formFields.BillingStreetName || "",
            streetNumber: context.state.formFields.BillingStreetNumber || "",
            city: context.state.formFields.BillingCity || "",
            postalCode: context.state.formFields.BillingPostalCode || "",
            coAddress: context.state.formFields.BillingCo || "",
            unit: context.state.formFields.BillingApartmentNumber || ""
        }
        moveOutObj.nextCustomer = {
            customerName: "",
            customerPhone: ""
        }

        let timeoutObj = {timeout: 60000};
        if (window.runtimeConfigTimeout) {            
            timeoutObj.timeout = window.runtimeConfigTimeout;
        }

        return axios.post(apiUrl.moveInOutMoveOut, moveOutObj, timeoutObj).then(response => {            
            context.commit('updateMoveOutResponse', response.data.data);
        }).catch(err => {
            if (err.response) {
                context.commit('updateResponseError', responseErrors.ELLEVIO_ERROR_4XX5XX);
            } else {
                context.commit('updateResponseError', responseErrors.ELLEVIO_ERROR_TIMEOUT);
            }
        });
    },
    contractInvoiceAddress(context){
        return axios.get(`${apiUrl.moveInOutContractInvoiceAddress}/${context.state.SelectedDeliverySite.deliverySiteId}`
        ).then(response => {
            context.commit('updateContractInvoiceAddress', response.data.data)
        });
    },
    sendForm(state){
        return axios.post(apiUrl.moveOutFormApi, state.formFields);
    },
    updateSelectedDeliverySite(context, payload){
        context.commit('updateSelectedDeliverySite', payload);
    },
    updateMoveOutDate(context, payload){
        context.commit('updateMoveOutDate', payload);
    },
    updateBillingStreetName(context, payload){
        context.commit('updateBillingStreetName', payload);
    },
    updateBillingStreetNumber(context, payload){
        context.commit('updateBillingStreetNumber', payload);
    },
    updateBillingApartmentNumber(context, payload){
        context.commit('updateBillingApartmentNumber', payload);
    },
    updateBillingPostalCode(context, payload){
        context.commit('updateBillingPostalCode', payload);
    },
    updateBillingCity(context, payload){
        context.commit('updateBillingCity', payload);
    },
    updateBillingCareOf(context, payload){
        context.commit('updateBillingCareOf', payload);
    },
    updatePhone(context, payload){
        context.commit('updatePhone', payload);
    },
    updateEmail(context, payload){
        context.commit('updateEmail', payload);
    },
};

const mutations = {
    clearState(state){
        state.formFields = {
            Phone: "",
            Email: "",
            MoveOutDate: "",
            SelectedDeliverySite: "",
            BillingStreetName: "",
            BillingPostalCode: "",
            BillingCity: "",
            BillingCo: "",
        };        
        state.responseObj = {};
    },
    updateMoveOutResponse(state, field){
        state.responseObj = field;
    },
    updateSelectedDeliverySite(state, field){
        state.SelectedDeliverySite = field;
    },
    updateMoveOutDate(state, field){
        state.formFields.MoveOutDate = field;
    },
    updateBillingStreetName(state, field){
        state.formFields.BillingStreetName = field;
    },
    updateBillingStreetNumber(state, field){
        state.formFields.BillingStreetNumber = field;
    },
    updateBillingApartmentNumber(state, field){
        state.formFields.BillingApartmentNumber = field;
    },
    updateBillingPostalCode(state, field){
        state.formFields.BillingPostalCode = field;
    },
    updateBillingCity(state, field){
        state.formFields.BillingCity = field;
    },
    updateBillingCareOf(state, field){
        state.formFields.BillingCo = field;
    },
    updatePhone(state, field){
        state.formFields.Phone = field;
    },
    updateEmail(state, field){
        state.formFields.Email = field;
    },
    updateResponseError(state, field){
        state.responseError = field;
    },
    updateContractInvoiceAddress(state, field){
        state.formFields.BillingStreetName = field.streetName || "";
        state.formFields.BillingStreetNumber = field.streetNumber || "";
        state.formFields.BillingPostalCode = field.postalCode || "";
        state.formFields.BillingCity = field.postOffice || "";        
        state.formFields.BillingApartmentNumber = field.unit || "";
        state.formFields.BillingCo = field.coAddress || "";
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}