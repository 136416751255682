export default {    

    
    moveInOutLogIn: '/api/moveinout/login',
    moveInOutTranslations: '/api/moveinout/translations',
    moveInOutGetDeliverySite: '/api/moveinout/deliverySite',
    moveInOutGetDeliverySitesByAddress: '/api/moveinout/deliverySites/getDeliverySitesByAddress',
    moveInOutContract: '/api/moveinout/contract',
    moveInOutContractInvoiceAddress: '/api/moveinout/contract/invoiceAddress',

    moveInOutSearchAddress: '/api/moveinout/searchAddress/query',
    moveInOutCustumerInfo: '/api/moveinout/customerInfo',
    
    powerOutageCustomerInfo: '/api/poweroutagereport/customerInfo',
    //powerOutageReportData: '/api/poweroutagedata',
    powerOutageReportData: '/api/poweroutagereport/data',
    powerOutageReportSubmit: '/api/poweroutagereport/submit',

    powerOutageOngoingOutage: '/api/poweroutagereport/ongoingOutage',
    
    
    moveInOutMoveIn: '/api/moveinout/moveIn',
    moveInOutMoveOut: '/api/moveinout/moveOut',
    moveInOutUpdateCustomerInfo: '/api/moveinout/updateCustomerInfo',


    //future endpoints for sending all data to - summary
    moveInFormApi: '',
    moveOutFormApi: ''

    /*
    //endpoints before change (getting different axios.deaults.baseURL based on environment)
    testData: 'http://localhost:8081/translations',
    releaseData: '/api/data',
    getDeliverySiteID: '/api/movein/searchDeliverySite',
    testDeliverySiteResponse: 'http://localhost:8081/deliverysites',
    powerOutageReportTestData: 'http://localhost:8081/poweroutagedata',
    searchAddress: '/api/movein/searchAddress/query',
    testSearchAddress: 'http://localhost:8081/matchingAddresses',
    testPersonalInfo: 'http://localhost:8081/personalInfo',
    powerOutageReportData: '/api/poweroutagereport/data'
    */
}