import Vuex from 'vuex';
import Vue from 'vue';
import VueRestrictedInput from 'vue-restricted-input';
import move from './modules/move';
import moveIn from './modules/moveIn';
import moveOut from './modules/moveOut';
import powerOutageReport from './modules/powerOutageReport'
import axios from 'axios';

axios.defaults.baseURL=process.env.VUE_APP_BASE_URL;
//axios.defaults.timeout = 5000;

//window.runtimeConfigTimeout = 1000;


/* Error handling test example. For production code, see main.js */
/*
if (typeof(Storage) !== "undefined") {
    localStorage.setItem('ellevio-error-500-heading', 'TESTING TESTING');
    localStorage.setItem('ellevio-error-500-preamble', 'this is a Preamble...');
    localStorage.setItem('ellevio-error-500-linkurl', 'https://google.se');
    localStorage.setItem('ellevio-error-500-labeltext', 'TO GOOGLE');

    localStorage.setItem('ellevio-error-timeout-heading', 'VUE_APP_TIMEOUT_TEXT_HEADING');
    localStorage.setItem('ellevio-error-timeout-preamble', 'VUE_APP_TIMEOUT_TEXT_PREAMBLE');
    localStorage.setItem('ellevio-error-timeout-linkurl', 'VUE_APP_TIMEOUT_TEXT_LINKURL');
    localStorage.setItem('ellevio-error-timeout-labeltext', 'VUE_APP_TIMEOUT_TEXT_LABELTEXT');
}
*/

Vue.use(Vuex)
Vue.use(VueRestrictedInput);

Vue.config.productionTip = false
Vue.config.devtools = true

export default new Vuex.Store({
    modules: {
        move,
        moveIn,
        moveOut,
        powerOutageReport
    }
})