const state = {    
    formFields: {
        SelectedDeliverySite: "",
        Mobile: "",
        Email: "",
    },
    submitResponse: {},
    customerInfo: {},
    data: {},
    answered: [],
    startAfterLogin: true,
    ongoingOutage: []
};

import apiUrl from "../../helpers/apiUrl"
import axios from 'axios'

const getters = {
    getForm: (state) => state.formFields,
    getData: (state) => state.data,
    getCustomerInfo: (state) => state.customerInfo,
    getStartAfterLogin: (state) => state.startAfterLogin,
    getAnswered: (state) => state.answered,
    getSubmitResponse: (state) => state.submitResponse,
    getOngoingOutage: (state) => state.ongoingOutage
};

const actions = {
    submit(context) {
        const submitObj = {};
        submitObj.deliverySiteId = state.formFields.SelectedDeliverySite.deliverySiteId || "";
        submitObj.answers = state.answered || [];
        submitObj.emailAddress = state.formFields.Email || "";
        submitObj.phoneNumber = state.formFields.Mobile.number || "";
        
        return axios.post(apiUrl.powerOutageReportSubmit, submitObj).then(response => {
            context.commit('updateSubmitResponse', response.data.data);
        });
    },
    sendForm(state){
        return axios.post(apiUrl.PowerOutageReportApi, state.formFields);
    },
    ongoingOutage(context){
        return axios.get(`${apiUrl.powerOutageOngoingOutage}/${state.formFields.SelectedDeliverySite.deliverySiteId}`).then(response => {
            context.commit('updateOngoingOutageResponse', response.data.data);
        })         
    },
    fetchData(context){
       return axios.get(apiUrl.powerOutageReportData).then(response => {
            context.commit('updateData', response.data.data);
        })        
    },
    fetchCustomerInfo(context){
        return axios.get(apiUrl.powerOutageCustomerInfo).then(response => {
            context.commit('updateCustomerInfo', response.data.data);
        })     
    },
    updateSelectedDeliverySite(context, payload){
        context.commit('updateSelectedDeliverySite', payload);
    },
    updateMobile(context, payload){
        context.commit('updateMobile', payload);
    },
    updateEmail(context, payload){
        context.commit('updateEmail', payload);
    },
    updateAnswered(context, payload) {
        context.commit('updateAnswered', payload);
    }
};

const mutations = {
    updateSubmitResponse(state, field){
        state.submitResponse = field;
    },
    updateName(state, field){
        state.formFields.Name = field;
    },
    updateOngoingOutageResponse(state, field){
        state.ongoingOutage = field
    },
    updateData(state, field){
        state.data = field;
    },
    pushAnswered(state, field){
        state.answered.push(field);
    },
    popAnswered(state){
        state.answered.pop();
    },
    updateAnswered(state, field) {
        state.answered = field;
    },
    updateCustomerInfo(state, field){
        state.customerInfo = field;
    },
    updateSelectedDeliverySite(state, field){
        state.formFields.SelectedDeliverySite = field;
    },
    updateMobile(state, field){
        state.formFields.Mobile = field;
    },
    updateEmail(state, field){
        state.formFields.Email = field;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}