const state = {
    formFields: {
        FullName: "",
        StreetName: "",
        HouseNumber: "",
        PostalCode: "",
        PostOffice: "",
        BillingStreetName: "",
        BillingHouseNumber: "",
        BillingPostalCode: "",
        BillingPostOffice: "",
        BillingCo: "",
        BillingApartmentNumber: "",
        DeliverySiteID: "",
        TypeOfResidence: null,
        MoveInDate: "",
        MoveInHour: null,
        MoveInMinute: null,
        Mobile: "",
        Email: "",
        OptionalMobile: "",
        OptionalEmail: "",
        OptionalContactName: "",
        ApartmentNumber: "",
        InvoiceType: "",
        InvoiceBank: "",
        BankName: "",
        EmailInvoice: "",
        MeterNumber: "",
        SeparateReconnectionContact: "",
        ReconnectionContactName: "",
        ReconnectionContactNumber: "",
    },
    responseError: "",
    currentlyUsedWayOfSearch: "",
    backedToFindMeterPage: false,
    deliverySiteInfoData: {},
    deliverySites: [],
    data: {},
    contractInfo: {},
    responseObj: {}
};

import apiUrl from "../../helpers/apiUrl"
import responseErrors from "../../helpers/responseErrors"
import axios from 'axios'

const getters = {
    getContractInfo: (state) => state.moveIn.contractInfo,
    getForm: (state) => state.formFields,
    getData: (state) => state.data,
    getAllDeliverySites: (state) => state.deliverySites,
    getApartmentDeliverySites: (state) => state.deliverySites.filter(site => site.address.premiseTypeId === '1'),
    getHouseDeliverySites: (state) => state.deliverySites.filter(site => site.address.premiseTypeId !== '1'),
    getIfBothApartmentAndHouse: (state) => state.deliverySites.filter(site => site.address.premiseTypeId !== '1').length > 0
                                && state.deliverySites.filter(site => site.address.premiseTypeId === '1').length > 0
};

const actions = {
    clearState(context) {
        context.commit('clearState')
    },
    moveIn(context){        
        const d = context.state.formFields.MoveInDate;
        const moveInObj = {};
        moveInObj.customerId = "";
        moveInObj.address = {
            firstName: context.rootState.move.customerInfo.firstName || "",
            lastName: context.rootState.move.customerInfo.lastName || "",
            fullName: `${context.rootState.move.customerInfo.firstName} ${context.rootState.move.customerInfo.lastName}`,
            ssn: context.rootState.move.customerInfo.personalIdentityNumber || "",
            email: context.state.formFields.Email || "",

            mobile: context.state.formFields.Mobile ? context.state.formFields.Mobile.number : "",
            //code used before country code was added
            //mobile: context.state.formFields.Mobile ? (context.state.formFields.Mobile.substr(0, 1) === '0' && context.state.formFields.Mobile.length === 10 ? context.state.formFields.Mobile : `0${context.state.formFields.Mobile}` ) : "",
            
            homePhone: ""
        };
        
        moveInObj.bankCode = context.state.formFields.InvoiceBank || "";
        moveInObj.bankName = context.state.formFields.BankName || "";

        moveInObj.invoiceEmailAddress = context.state.formFields.EmailInvoice || "";
        moveInObj.deliverySiteId = context.state.formFields.DeliverySiteID || "";
        moveInObj.moveInDate = context.state.formFields.MoveInDate ? `${d.getFullYear()}-${("0"+(d.getMonth()+1)).slice(-2)}-${("0" + d.getDate()).slice(-2)}` : "";
        moveInObj.invoiceAddress = {
            streetName: context.state.formFields.BillingStreetName || context.state.deliverySiteInfoData.address.streetName || "",
            streetNumber: context.state.formFields.BillingHouseNumber || context.state.deliverySiteInfoData.address.streetNumber || "",
            city: context.state.formFields.BillingPostOffice || context.state.deliverySiteInfoData.address.postOffice || "",
            postalCode: context.state.formFields.BillingPostalCode || context.state.deliverySiteInfoData.address.postalCode || "",
            coAddress: context.state.formFields.BillingCo || "",
            unit: context.state.formFields.BillingApartmentNumber || context.state.deliverySiteInfoData.address.unit || ""
        }

        if (context.state.deliverySiteInfoData.isOrderReconnection) {
            const newContactDetails = context.state.formFields.SeparateReconnectionContact === 'change-info-yes'
            const email = context.state.formFields.Email || context.rootState.move.customerInfo.email
            const fullname = context.state.formFields.FullName || context.rootState.move.customerInfo.fullName

            moveInObj.orderReconnection = {
                name: newContactDetails ? context.state.formFields.OptionalContactName : fullname,
                mobile: newContactDetails ? context.state.formFields.OptionalMobile.number : context.state.formFields.Mobile.number,
                email: newContactDetails ? context.state.formFields.OptionalEmail : email,
                date: moveInObj.moveInDate,
                time: {
                    hours: context.state.formFields.MoveInHour,
                    minutes: context.state.formFields.MoveInMinute
                }                
            };
        } else {
            if (moveInObj.orderReconnection) delete moveInObj.orderReconnection;
        }

        let timeoutObj = {timeout: 60000};
        if (window.runtimeConfigTimeout) {
            timeoutObj.timeout = window.runtimeConfigTimeout;
        }
        
        return axios.post(apiUrl.moveInOutMoveIn, moveInObj, timeoutObj).then(response => {
            context.commit('updateMoveInResponse', response.data.data);
        }).catch(err => {
            if (err.response) {
                context.commit('updateResponseError', responseErrors.ELLEVIO_ERROR_4XX5XX);
            } else {
                context.commit('updateResponseError', responseErrors.ELLEVIO_ERROR_TIMEOUT);
            }
        });        
    },
    contract(context){
        return axios.get(`${apiUrl.moveInOutContract}/${state.deliverySiteInfoData.deliverySiteId}`
        ).then(response => {
            context.commit('updateContractInfo', response.data.data)
        })
    },
    getDeliverySite(context){
        return axios.get(`${apiUrl.moveInOutGetDeliverySite}/${state.formFields.DeliverySiteID}`
        ).then(response => {
            if(response && JSON.stringify(response.data.data) !== '{}' && response.data.data !== null){
                
                let dataCopy = {...response.data.data}
                if (dataCopy.errors.length > 0 && dataCopy.isOrderReconnection === true) {
                    dataCopy.isOrderReconnection = false
                }
                
                context.commit('updateDeliverySiteInfoData', dataCopy)
                if(!Array.isArray(dataCopy)){
                    context.commit('updateDeliverySiteID', dataCopy.deliverySiteId)
                    context.commit('updateApartmentNumber', dataCopy.address?.unit)                    
                }
            }
        })
    },
    getDeliverySitesByAddress(context, payload){
        return axios.get(apiUrl.moveInOutGetDeliverySitesByAddress, {
            params: payload
        }).then(response => {
            context.commit('updateDeliverySiteID', '')
            context.commit('updateDeliverySites', response.data.data)

        })
    },
    searchAddress(context, payload){
        return axios.get(`${apiUrl.moveInOutSearchAddress}/${payload}`)
        //return axiosInstance.axiosTestInstance.get(`${apiUrl.moveInOutSearchAddress}/${payload}`)
    },
    updateMoveInDate(context, payload){
        context.commit('updateMoveInDate', payload);
    },
    updateMoveInHour(context, payload){
        context.commit('updateMoveInHour', payload);
    },
    updateMoveInMinute(context, payload){
        context.commit('updateMoveInMinute', payload);
    },
    updateMobile(context, payload){
        context.commit('updateMobile', payload);
    },
    updateOptionalMobile(context, payload){
        context.commit('updateOptionalMobile', payload);
    },
    updateOptionalContactName(context, payload){
        context.commit('updateOptionalContactName', payload);
    },
    updateEmail(context, payload){
        context.commit('updateEmail', payload);
    },
    updateOptionalEmail(context, payload){
        context.commit('updateOptionalEmail', payload);
    },
    updateTypeOfResidence(context, payload){
        context.commit('updateTypeOfResidence', payload);
    },
    updateInvoiceType(context, payload){
        context.commit('updateInvoiceType', payload);
    },
    updateInvoiceBank(context, payload){
        context.commit('updateInvoiceBank', payload);
    },
    updateBankName(context, payload){
        context.commit('updateBankName', payload);
    },
    updateEmailInvoice(context, payload){
        context.commit('updateEmailInvoice', payload);
    },
    updateSeparateReconnectionContact(context, payload){
        context.commit('updateSeparateReconnectionContact', payload);
    },
    updateReconnectionContactName(context, payload){
        context.commit('updateReconnectionContactName', payload);
    },
    updateReconnectionContactNumber(context, payload){
        context.commit('updateReconnectionContactNumber', payload);
    },
    updateAddress(context, payload){
        context.commit('updateAddress', payload);
    },
    updateBillingAddress(context, payload){
        context.commit('updateBillingAddress', payload);
    },
    updateBillingCo(context, payload){
        context.commit('updateBillingCo', payload);
    },
    updateBillingApartmentNumber(context, payload){
        context.commit('updateBillingApartmentNumber', payload);
    },
    updateDeliverySiteID(context, payload){
        context.commit('updateDeliverySiteID', payload);
    },
    updateDeliverySiteInfoData(context, payload){
        context.commit('updateDeliverySiteInfoData', payload)
    },
    updateApartmentNumber(context, payload){
        context.commit('updateApartmentNumber', payload)
    },
    updateCurrentlyUsedWayOfSearch(context, payload){
        context.commit('updateCurrentlyUsedWayOfSearch', payload)
    },    
    updateBackedToFindMeterPage(context, payload){
        context.commit('updateBackedToFindMeterPage', payload)
    },
};

const mutations = {
    clearState(state){
        state.formFields = {
            FullName: "",
            StreetName: "",
            HouseNumber: "",
            PostalCode: "",
            PostOffice: "",
            BillingStreetName: "",
            BillingHouseNumber: "",
            BillingPostalCode: "",
            BillingPostOffice: "",
            BillingCo: "",
            BillingApartmentNumber: "",
            DeliverySiteID: "",
            TypeOfResidence: null,
            MoveInDate: "",
            MoveInHour: null,
            MoveInMinute: null,
            Mobile: "",
            OptionalMobile: "",
            OptionalEmail: "",
            OptionalContactName: "",
            Email: "",
            ApartmentNumber: "",
            InvoiceType: "",
            BankName: "",
            InvoiceBank: "",
            EmailInvoice: "",
            MeterNumber: "",
            SeparateReconnectionContact: "",
            ReconnectionContactName: "",
            ReconnectionContactNumber: "",
        };
        state.deliverySiteInfoData = {};
        state.deliverySites = [];
        state.data = {};
        state.contractInfo = {};
        state.responseObj = {};
    },
    updateMoveInResponse(state, field){
        state.responseObj = field;
    },
    updateDeliverySites(state, field){
        state.deliverySites = field;
    },
    updateAddress(state, field){
        state.formFields.StreetName = field.street;
        state.formFields.HouseNumber = field.street_number + field.letter;
        state.formFields.PostalCode = field.postalCode;
        state.formFields.PostOffice = field.locality;
    },
    updateBillingAddress(state, field){
        state.formFields.BillingStreetName = field.street;
        state.formFields.BillingHouseNumber = field.street_number;
        state.formFields.BillingPostalCode = field.postalCode;
        state.formFields.BillingPostOffice = field.locality;
    },
    updateDeliverySiteID(state, field){
        state.formFields.DeliverySiteID = field;
    },
    updateBillingCo(state, field){
        state.formFields.BillingCo = field;
    },
    updateBillingApartmentNumber(state, field){
        state.formFields.BillingApartmentNumber = field;
    },
    updateMoveInDate(state, field){
        state.formFields.MoveInDate = field;
    },
    updateMoveInHour(state, field){
        state.formFields.MoveInHour = field;
    },
    updateMoveInMinute(state, field){
        state.formFields.MoveInMinute = field;
    },
    updateMobile(state, field){
        state.formFields.Mobile = field;
    },
    updateOptionalMobile(state, field){
        state.formFields.OptionalMobile = field;
    },
    updateOptionalContactName(state, field){
        state.formFields.OptionalContactName = field;
    },
    updateEmail(state, field){
        state.formFields.Email = field;
    },
    updateOptionalEmail(state, field){
        state.formFields.OptionalEmail = field;
    },
    updateTypeOfResidence(state, field){
        state.formFields.TypeOfResidence = field;
    },
    updateApartmentNumber(state, field){
        state.formFields.ApartmentNumber = field;
    },
    updateInvoiceType(state, field){
        state.formFields.InvoiceType = field;
    },
    updateInvoiceBank(state, field){
        state.formFields.InvoiceBank = field;
    },
    updateBankName(state, field){
        state.formFields.BankName = field;
    },
    updateEmailInvoice(state, field){
        state.formFields.EmailInvoice = field;
    },
    updateSeparateReconnectionContact(state, field){
        state.formFields.SeparateReconnectionContact = field;
    },
    updateReconnectionContactName(state, field){
        state.formFields.ReconnectionContactName = field;
    },
    updateReconnectionContactNumber(state, field){
        state.formFields.ReconnectionContactNumber = field;
    },
    updateDeliverySiteInfoData(state, field){
        state.deliverySiteInfoData = field;
    },
    updateContractInfo(state, field){
        state.contractInfo = field;
    },
    updateCurrentlyUsedWayOfSearch(state, field){
        state.currentlyUsedWayOfSearch = field;        
    },
    updateBackedToFindMeterPage(state, field){
        state.backedToFindMeterPage = field;        
    },
    updateResponseError(state, field){
        state.responseError = field;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}