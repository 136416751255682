import Vue from 'vue';
import Vuex from 'vuex';
import VueRestrictedInput from 'vue-restricted-input';
import store from './store';
import axios from 'axios';

Vue.use(Vuex);
Vue.use(VueRestrictedInput);
Vue.config.productionTip = false
Vue.config.devtools = true

const getRuntimeConfig = async () => {
  const runtimeConfig = await fetch('/runtimeConfig.json');
  return await runtimeConfig.json()
}

getRuntimeConfig().then(function(config) {
  axios.defaults.baseURL = config.VUE_APP_BASE_URL || "http://localhost:8081";
  
  //axios.defaults.timeout = config.VUE_APP_TIMEOUT || 60000;
  window.runtimeConfigTimeout = config.VUE_APP_TIMEOUT || 60000;
  
  if (typeof(Storage) !== "undefined") {
    localStorage.setItem('ellevio-error-500-heading', config.VUE_APP_500_TEXT_HEADING) || '';
    localStorage.setItem('ellevio-error-500-preamble', config.VUE_APP_500_TEXT_PREAMBLE) || '';
    localStorage.setItem('ellevio-error-500-linkurl', config.VUE_APP_500_TEXT_LINKURL) || '';
    localStorage.setItem('ellevio-error-500-labeltext', config.VUE_APP_500_TEXT_LABELTEXT) || '';

    localStorage.setItem('ellevio-error-timeout-heading', config.VUE_APP_TIMEOUT_TEXT_HEADING) || '';
    localStorage.setItem('ellevio-error-timeout-preamble', config.VUE_APP_TIMEOUT_TEXT_PREAMBLE) || '';
    localStorage.setItem('ellevio-error-timeout-linkurl', config.VUE_APP_TIMEOUT_TEXT_LINKURL) || '';
    localStorage.setItem('ellevio-error-timeout-labeltext', config.VUE_APP_TIMEOUT_TEXT_LABELTEXT) || '';
  }

  new Vue({
    store,
    el: '.vue-app',
    components: {
      'EllevioMove': () => import('./apps/MoveInOut/App'),
      'EllevioPowerOutageReport': () => import('./apps/PowerOutageReport/App'),
    }    
  })



});